<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Aws from "@/services/Aws";
import modalCreateReadDBInstance from "@/components/modals/monitor/aws/modalCreateReadDBInstance";
import Swal from "sweetalert2";

export default {
    components: { Layout, PageHeader, modalCreateReadDBInstance },
    data() {
        return {
            title: "AWS DB Instances",
            error: null,
            DBinstances: [],
            tableData: [],
            dbData: [],
            clusterName: '',
            items: [
                {
                    text: "AWS",
                },
                {
                    text: "DB Instances",
                    active: true,
                },
            ] ,
            showModal: false,
            readerCount:[]
        };
    },
    async mounted() {
        this.getRDSInstances()
    },
    methods: {
        isNumeric(value) {
            return !isNaN(parseFloat(value)) && isFinite(value);
        },

        getRDSInstances() {
            Aws.getRDSInstances()
                .then((response) => {
                    if (response.data) {
                        let DBinstances = response.data.data;
                        // Organize instances by DBClusterIdentifier
                        const groupedInstances = DBinstances.reduce((acc, instance) => {
                            const clusterId = instance.DBClusterIdentifier || 'No Cluster'; // Default to 'No Cluster' if not present
                            if (!acc[clusterId]) {
                                acc[clusterId] = [];
                            }

                            acc[clusterId].push(instance);

                            return acc;
                        }, {});

                        this.tableData = groupedInstances;

                        this.readerCount = Object.keys(groupedInstances).reduce((countAcc, clusterId) => {
                            countAcc[clusterId] = groupedInstances[clusterId].filter(
                                instance => instance.InstanceRole === 'reader' && instance.DBInstanceStatus != 'deleting'
                            ).length;
                            return countAcc;
                        }, {});

                    } else {
                        this.tableData = [];
                    }
                })
                .catch((error) => {
                    this.error = error.response && error.response.data && error.response.data.error
                        ? error.response.data.error
                        : "An error occurred";
                    this.tableData = [];
                });
        },

        callModalcreateReadDBInstance(clusterName, dbData){
            this.$bvModal.show("createReadDBInstance_edit");
            this.clusterName = clusterName;
            this.dbData = dbData;
        },

        modalDeleteDBReader(DBInstanceIdentifier){

            Swal.fire({
                title: "Remove reader instance <br/>"+DBInstanceIdentifier+"?",
                icon: "warning",
                html: `
                        <p>Type "Delete" to confirm:</p>
                        <input type="text" id="deleteConfirmation" class="swal2-input" autocomplete="off">
                    `,
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Delete",
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    const confirmationText = Swal.getPopup().querySelector(
                        "#deleteConfirmation"
                    ).value;
                    if (confirmationText.toLowerCase() !== "delete") {
                        Swal.showValidationMessage('You must type "Delete" to confirm');
                        return false;
                    }

                    return Aws.removeReadDBInstance(DBInstanceIdentifier)
                        .then((response) => {
                            return response;
                        })
                        .catch((error) => {
                            let errorMsg = error.response.data.error
                                ? error.response.data.error
                                : "";
                            this.failedmsg("Fail!", errorMsg);
                        });
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    const res = result.value.data ? result.value.data.success : false;
                    const error = result.value.data.error
                        ? result.value.data.error
                        : "Failed";

                    if (res) {
                        this.successmsg('Removed Instance', 'DB Instance is successfully removed')
                        setTimeout(() => {
                            this.getRDSInstances();
                        }, 2000);
                    } else {
                        this.warningmsg("Fail!", error);
                    }
                }
            });

        }

    },
    middleware: "authentication",
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12" >
                <div class="card" v-for="(dbData, clusterName) in tableData" :key="clusterName">
                    <div class="card-body">

                        <div class="row mb-2">
                            <div class="col-6">
                                <h4>Cluster name: <span class="ms-2">{{clusterName}}</span></h4>
                            </div>
                            <div class="col-6" >
                                <b-button class="float-end" variant="primary"
                                          v-if="dbData[0] && dbData[0].canChange"
                                          @click="callModalcreateReadDBInstance(clusterName, dbData)">Create Read Instance</b-button>
                            </div>
                        </div>

                        <table class="table table-responsive">
                            <thead>
                                <tr>
                                    <th>DB Identifier</th>
                                    <th>DB Class</th>
                                    <th>Engine</th>
                                    <th>Instance Role</th>
                                    <th>Publicly Accessible</th>
                                    <th>Availability Zone</th>
                                    <th>DB Status</th>
                                    <th>CPU Utilization</th>
                                    <th>Note</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in dbData" :key="item.DBInstanceIdentifier">
                                    <td>{{item.DBInstanceIdentifier}}</td>
                                    <td>{{item.DBInstanceClass}}</td>
                                    <td>{{item.Engine}}</td>
                                    <td>{{item.InstanceRole}}</td>
                                    <td>{{item.PubliclyAccessible}}</td>
                                    <td>{{item.AvailabilityZone}}</td>
                                    <td><div class="font-size-16" v-html="format_status(item.DBInstanceStatus)"></div></td>
                                    <td>

                                        <div class="progress position-relative" v-if="isNumeric(item.CPUUtilization)" style="height: 25px;">
                                            <div
                                                    class="progress-bar progress-bar-striped progress-bar-animated"
                                                    role="progressbar"
                                                    :aria-valuenow="item.CPUUtilization"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                    :style="{ width: `${item.CPUUtilization}%` }"
                                            ></div>
                                            <span class="position-absolute w-100 text-center cpuProgressValue">
                                                {{ parseFloat(item.CPUUtilization).toFixed(2) }}%
                                            </span>
                                        </div>
                                        <div v-else class="text-danger">
                                            Invalid CPU Utilization value
                                        </div>
                                    </td>
                                    <td>{{item.note}}</td>
                                    <td>
                                        <ul class="list-inline mb-0">
                                            <li class="list-inline-item">
                                                <a href="javascript:void(0);" title="Delete DB Instance" class="text-danger"
                                                   v-if="item.InstanceRole == 'reader' && (item.DBInstanceStatus != 'deleting' || item.DBInstanceStatus != 'creating') && readerCount[clusterName] > 1"
                                                   @click="modalDeleteDBReader(item.DBInstanceIdentifier)" >
                                                    <i class="uil uil-trash-alt font-size-18"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <modalCreateReadDBInstance
                :dbData="this.dbData"
                :clusterName="this.clusterName"
                @onRefresh="getRDSInstances()"
        ></modalCreateReadDBInstance>

    </Layout>
</template>
